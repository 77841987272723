body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333;
  color: #f9f9f9;
}
/* .nav {
  background-color: #333333;
}
.main {
  background-color: #333333;
} */
.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(45, 212, 191);
  z-index: 50;
}
html {
  scroll-behavior: smooth;
}
.active-link {
  font-weight: bold; /* Optional: make the link bold */
}
hr {
  border-color: rgb(107 114 128);
  background-color: rgb(107 114 128);
  height: 1px;
}
.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}
